import { Button } from 'antd';
import { useIntl } from 'react-intl';
import { Outlet, useLocation, useNavigate, Navigate } from 'react-router-dom';
import styled from 'styled-components';

import loginBg from 'assets/images/login-bg.webp';
import Logo from 'assets/images/logo-32.svg?rc';
import LogoWhite from 'assets/images/logo-white.svg?rc';

import { getLocalStorage, STORAGE_TOKEN } from 'utils';

const MainStyled = styled.main`
  background-color: #ff5258;
  display: flex;
  min-height: 100vh;

  .main-left {
    display: none;
    flex-grow: 1;
    img {
      margin-top: auto;
      width: 100%;
    }

    .main-logo {
      position: absolute;
      top: 40px;
      left: 40px;
    }

    @media (min-width: 567px) {
      display: flex;
    }
  }

  .main-right {
    max-width: 100%;
    width: 100%;
    background-color: #f5f5fb;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    padding: 0 20px;

    @media (min-width: 567px) {
      max-width: 560px;
      width: auto;
    }
    @media (min-width: 992px) {
      padding: 0 117px;
    }
  }

  .main-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: ${({ $login }) => ($login ? '350px' : '550px')};
  }

  .main-footer {
    color: #adb2b6;
    font-size: 16px;
    margin-bottom: 40px;

    .ant-btn-link {
      padding: 0;
      height: auto;
      line-height: 1;
      border-bottom: 1px solid #ff5258;
      border-radius: 0;
      margin-left: 5px;
    }
  }

  .logo-icon {
    margin-bottom: 40px;
  }

  .authen-form {
    width: 280px;
    display: flex;
    flex-direction: column;

    .ant-input-affix-wrapper {
      height: 48px;
      border-radius: 4px;
      margin-bottom: 8px;
    }

    .ant-btn {
      border-radius: 4px;
      height: 50px;
      margin-top: 15px;
    }

    .ant-input-clear-icon {
      display: flex;
    }

    .authen-error-message {
      width: 100%;
      font-size: 14px;
      height: 14px;
      color: #f00;
    }

    @media (min-width: 567px) {
      width: 327px;
    }
  }

  .ant-form-item {
    margin-bottom: 0;

    .ant-input-prefix {
      margin-right: 0;
    }
    .require-sign {
      font-size: 16px;
      color: #ff5258;
    }
  }
`;

const BaseLayout = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate(pathname === '/login' ? '/signup' : '/login');
  };

  return !getLocalStorage(STORAGE_TOKEN) ? (
    <MainStyled $login={pathname === '/login'}>
      <div className="main-left">
        <LogoWhite className="main-logo" />
        <img src={loginBg} alt="background" />
      </div>
      <div className="main-right">
        <div className="main-form">
          <Logo className="logo-icon" />
          <Outlet />
        </div>
        <span className="main-footer">
          {pathname === '/login'
            ? formatMessage({ defaultMessage: '아직 봉봉 멤버가 아니신가요?' })
            : formatMessage({ defaultMessage: '이미 봉봉 멤버이신가요?' })}
          <Button type="link" onClick={navigateTo}>
            {pathname === '/login'
              ? formatMessage({ defaultMessage: '가입하기' })
              : formatMessage({ defaultMessage: '로그인' })}
          </Button>
        </span>
      </div>
    </MainStyled>
  ) : (
    <Navigate to="/" replace />
  );
};

export default BaseLayout;
