import { useSWR } from 'hooks/api';

import { getAPI } from 'utils';

export default function useGetMe() {
  return useSWR('/users/me', getAPI);
}
export function useIsAdmin() {
  const { data } = useSWR('/users/me', getAPI);
  return data?.role?.type === 'master';
}
