import App from 'containers/App';
// import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';

import LocaleProvider from 'i18n/LocaleProvider';

import { swrOptions } from 'utils';
import './index.css';
import './styles/global.scss';

const root = createRoot(document.getElementById('root'));

root.render(
  // <StrictMode>
  <LocaleProvider>
    <BrowserRouter>
      <SWRConfig value={swrOptions}>
        <App />
      </SWRConfig>
    </BrowserRouter>
  </LocaleProvider>,
  // </StrictMode>,
);
