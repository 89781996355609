import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazyHomeBanner = lazy(() => import('./HomeBanner'));

const HomeBanner = (props) => (
  <Loadable>
    <LazyHomeBanner {...props} />
  </Loadable>
);

export default HomeBanner;
