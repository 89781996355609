export const TIME_FORMAT = 'HH:mm';
export const FULL_DATE_FORMAT = 'YYYY.MM.DD HH:mm:ss ZZ';
export const DATE_TIME_FORMAT = 'YYYY.MM.DD HH:mm';
export const DATE_FORMAT = 'YYYY.MM.DD';
export const STORAGE_TOKEN = 'token';
export const STORAGE_GUEST = 'vonvon-guest';
export const EXPIRE_TIME = 'expire-time';
export const BASE_URL = process.env.RB_BASE_URL;
export const USER_SITE_URL = process.env.USER_SITE_URL;

// export const ROUTES_PATH = {
//   home: '/',
//   contentEdited: '/content-edited',
//   userManagement: '/user-management',
//   approvalRequest: '/approval-request',
//   secretKeyword: '/secret-keyword',
//   memberManagement: '/member-management',
//   homeBanner: '/home-banner',
//   homeTopRanking: '/home-top-ranking',
//   homeCategory: '/home-category',
//   originalCategory:'/original-category',
//   quizCreate: '/quiz/create',

// };
export const ROLES = [
  {
    icon: '👻',
    type: 'guest',
    text: (formatMessage) => formatMessage({ defaultMessage: 'Guest' }),
  },
  {
    icon: '👾',
    type: 'member',
    text: (formatMessage) => formatMessage({ defaultMessage: 'Member' }),
  },
  {
    icon: '🤠',
    type: 'master',
    text: (formatMessage) => formatMessage({ defaultMessage: 'Admin' }),
  },
  {
    icon: '😎',
    type: 'admin',
    text: (formatMessage) => formatMessage({ defaultMessage: 'Manager' }),
  },
];

export const ALGORITHM_TYPES = [
  {
    value: 'personality',
    text: (formatMessage) => formatMessage({ defaultMessage: '성격형' }),
  },
  {
    value: 'random',
    text: (formatMessage) => formatMessage({ defaultMessage: '랜덤형' }),
  },
  {
    value: 'point',
    text: (formatMessage) => formatMessage({ defaultMessage: '배점형' }),
  },
];

export const QUESTION_TYPES = [
  {
    value: 'A',
    text: (formatMessage) => formatMessage({ defaultMessage: '기본형-A타입' }),
    short: (formatMessage) => formatMessage({ defaultMessage: '기본 A' }),
  },
  {
    value: 'B',
    text: (formatMessage) => formatMessage({ defaultMessage: '기본형-B타입' }),
    short: (formatMessage) => formatMessage({ defaultMessage: '기본 B' }),
  },
  {
    value: 'C',
    text: (formatMessage) => formatMessage({ defaultMessage: '기본형-C타입' }),
    short: (formatMessage) => formatMessage({ defaultMessage: '기본 C' }),
  },
  {
    value: 'D',
    text: (formatMessage) => formatMessage({ defaultMessage: '기본형-D타입' }),
    short: (formatMessage) => formatMessage({ defaultMessage: '기본 D' }),
  },
  {
    value: 'SA',
    text: (formatMessage) =>
      formatMessage({ defaultMessage: '스토리전개(A타입)' }),
    short: (formatMessage) => formatMessage({ defaultMessage: '스토리전개 A' }),
  },
  {
    value: 'SSA',
    text: (formatMessage) =>
      formatMessage({ defaultMessage: '스토리전개(주관식A)' }),
    short: (formatMessage) =>
      formatMessage({ defaultMessage: '스토리전개 주관식A' }),
  },
  {
    value: 'SSB',
    text: (formatMessage) =>
      formatMessage({ defaultMessage: '스토리전개(주관식B)' }),
    short: (formatMessage) =>
      formatMessage({ defaultMessage: '스토리전개 주관식B' }),
  },
];

export const FONTS = [
  {
    id: 1,
    name: (formatMessage) =>
      formatMessage({ defaultMessage: '나눔바른고딕 Regular' }),
    value: 'NanumBarunGothic',
    weight: '400',
  },
  {
    id: 2,
    name: (formatMessage) =>
      formatMessage({ defaultMessage: '나눔바른고딕 Bold' }),
    value: 'NanumBarunGothic',
    weight: '900',
  },
  {
    id: 3,
    name: (formatMessage) =>
      formatMessage({ defaultMessage: '나눔바른고딕 Light' }),
    value: 'NanumBarunGothic',
    weight: '300',
  },
  {
    id: 4,
    name: (formatMessage) => formatMessage({ defaultMessage: '수트 Bold' }),
    value: 'SUIT-Bold',
    weight: 'normal',
  },
  {
    id: 5,
    name: (formatMessage) => formatMessage({ defaultMessage: '수트 Medium' }),
    value: 'SUIT-Medium',
    weight: 'normal',
  },
  {
    id: 6,
    name: (formatMessage) => formatMessage({ defaultMessage: '수트 Thin' }),
    value: 'SUIT-Thin',
    weight: 'normal',
  },
  {
    id: 7,
    name: (formatMessage) => formatMessage({ defaultMessage: '이사만루 Bold' }),
    value: 'GongGothicBold',
    weight: 'normal',
  },
  {
    id: 8,
    name: (formatMessage) =>
      formatMessage({ defaultMessage: '이사만루 Medium' }),
    value: 'GongGothicMedium',
    weight: 'normal',
  },
  {
    id: 9,
    name: (formatMessage) =>
      formatMessage({ defaultMessage: '이사만루 Light' }),
    value: 'GongGothicLight',
    weight: 'normal',
  },
  {
    id: 10,
    name: (formatMessage) =>
      formatMessage({ defaultMessage: '지마켓 산스 Bold' }),
    value: 'GmarketSansBold',
    weight: 'normal',
  },
  {
    id: 11,
    name: (formatMessage) =>
      formatMessage({ defaultMessage: '지마켓 산스 Medium' }),
    value: 'GmarketSansMedium',
    weight: 'normal',
  },
  {
    id: 12,
    name: (formatMessage) =>
      formatMessage({ defaultMessage: '지마켓 산스 Light' }),
    value: 'GmarketSansLight',
    weight: 'normal',
  },
  {
    id: 13,
    name: (formatMessage) => formatMessage({ defaultMessage: '순천체 Bold' }),
    value: 'SuncheonB',
    weight: 'normal',
  },
  {
    id: 14,
    name: (formatMessage) =>
      formatMessage({ defaultMessage: '순천체 Regular' }),
    value: 'SuncheonR',
    weight: 'normal',
  },
  {
    id: 15,
    name: (formatMessage) => formatMessage({ defaultMessage: '이서윤체' }),
    value: 'LeeSeoyun',
    weight: 'normal',
  },
  {
    id: 16,
    name: (formatMessage) =>
      formatMessage({ defaultMessage: 'Neo 둥근모 Pro' }),
    value: 'NeoDunggeunmo',
    weight: 'normal',
  },
];

export const CATEGORY_ACTION = {
  addCategory: 'addCategory',
  updateCategory: 'updateCategory',
  deleteCategory: 'removeCategory',
  addQuiz: 'addQuiz',
  removeQuiz: 'removeQuiz',
};
