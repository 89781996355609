import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazyMemberManagement = lazy(() => import('./MemberManagement'));

const MemberManagement = (props) => (
  <Loadable>
    <LazyMemberManagement {...props} />
  </Loadable>
);

export default MemberManagement;
