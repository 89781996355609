import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazyHomeCategory = lazy(() => import('./HomeCategory'));

const HomeCategory = (props) => (
  <Loadable>
    <LazyHomeCategory {...props} />
  </Loadable>
);

export default HomeCategory;
