import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazySecretKeyword = lazy(() => import('./SecretKeyword'));

const SecretKeyword = (props) => (
  <Loadable>
    <LazySecretKeyword {...props} />
  </Loadable>
);

export default SecretKeyword;
