import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Layout, Menu } from 'antd';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Logo from 'assets/images/logo_24.svg?rc';
import LogoutIcon from 'assets/images/logout.svg?rc';
import testIcon from 'assets/images/test-icon.png';
import { useGetMe, useLogout } from 'hooks/services';

const HeaderStyled = styled(Layout.Header)`
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 60px;
  line-height: 80px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8ea;
  padding: 0 20px;

  .ant-scrolling-effect& {
    background-color: red;
  }

  @media (min-width: 992px) {
    height: 80px;
  }
`;

const LogoStyled = styled(Link)`
  display: flex;
  margin: 0 -20px;

  svg {
    transform: scale(0.8);
  }

  @media (min-width: 992px) {
    margin: 0;

    svg {
      transform: scale(1);
    }
  }
`;

const CreateTestButtonStyled = styled(Button)`
  display: flex;
  padding: 0 10px;
  align-items: center;
  margin-left: 15px;
  height: 32px;
  font-size: 14px;

  img {
    margin-right: 5px;
  }

  @media (min-width: 992px) {
    margin-left: 196px;
    height: 40px;
    font-size: 18px;
  }
`;

const LogoutButtonStyled = styled(Button)`
  height: 40px;
  font-size: 18px;
  padding: 4px 15px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`;

const HeaderRightStyled = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  .header-lg {
    display: none;
  }

  .header-small {
    display: flex;
  }

  @media (min-width: 992px) {
    .header-lg {
      display: flex;
      align-items: center;
    }
    .header-small {
      display: none;
    }
  }
`;

const UserStyled = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-left: 10px;
`;

// eslint-disable-next-line arrow-body-style
const Header = ({ hasCreateTestButton = true }) => {
  const { formatMessage } = useIntl();

  const { data } = useGetMe();
  const logout = useLogout();

  const handleMenuClick = (e) => {
    if (e.key === 'logout') {
      logout();
    }
  };

  return (
    <HeaderStyled>
      <LogoStyled to="/">
        <Logo />
      </LogoStyled>

      {hasCreateTestButton && (
        <Link to="/quiz/create">
          <CreateTestButtonStyled
            type="primary"
            icon={<img src={testIcon} alt="create a test icon" />}
          >
            {formatMessage({
              defaultMessage: '테스트 만들기',
            })}
          </CreateTestButtonStyled>
        </Link>
      )}
      <HeaderRightStyled>
        <div className="header-lg">
          <UserStyled
            style={{
              borderRight: '1px solid #ddd',
              paddingRight: 20,
              marginRight: 20,
            }}
          >
            {data?.avatar ? (
              <Avatar size={36} src={data.avatar.url} />
            ) : (
              <Avatar size={36} icon={<UserOutlined />} />
            )}
            <span style={{ marginLeft: 5 }}>{data?.username}</span>
          </UserStyled>
          <LogoutButtonStyled
            type="primary"
            style={{ marginRight: 40 }}
            onClick={() => logout()}
          >
            <LogoutIcon />
            {formatMessage({
              defaultMessage: '로그아웃',
            })}
          </LogoutButtonStyled>
        </div>
        <Dropdown
          className="header-small"
          overlay={
            <Menu
              onClick={handleMenuClick}
              items={[
                {
                  label: data?.username,
                  key: 'username',
                },
                {
                  type: 'divider',
                },
                {
                  label: formatMessage({
                    defaultMessage: '로그아웃',
                  }),
                  key: 'logout',
                },
              ]}
            />
          }
          trigger={['click']}
        >
          <UserStyled>
            {data?.avatar ? (
              <Avatar size={30} src={data.avatar.url} />
            ) : (
              <Avatar size={30} icon={<UserOutlined />} />
            )}
          </UserStyled>
        </Dropdown>
      </HeaderRightStyled>
    </HeaderStyled>
  );
};

export default Header;
