import { useNavigate } from 'react-router-dom';

import { setExpiredTime, setLocalStorage, STORAGE_TOKEN } from 'utils';

import { usePostJSON } from '../api';

const useLogin = () => {
  const postJSON = usePostJSON();

  return async ({ email, password }) => {
    await postJSON('/auth/local', {
      identifier: email,
      password,
    });
  };
};

const useVerifyLogin = () => {
  const navigate = useNavigate();
  const postJSON = usePostJSON();

  return async ({ email, code }) => {
    const res = await postJSON('/auth/local/verify', {
      email,
      code,
    });
    if (res?.jwt) {
      setLocalStorage(STORAGE_TOKEN, res.jwt);
      setExpiredTime(res);
      navigate('/', { replace: true });
    }
  };
};

export { useLogin, useVerifyLogin };
