import { useSWR } from 'hooks/api';

import { getAPI } from 'utils';

export default function useGetCategories(type) {
  return useSWR(
    `/admin/categories?filters[type]=${type}&sort[order]=asc`,
    getAPI,
  );
}
