import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazyApprovalRequest = lazy(() => import('./ApprovalRequest'));

const ApprovalRequest = (props) => (
  <Loadable>
    <LazyApprovalRequest {...props} />
  </Loadable>
);

export default ApprovalRequest;
