import { useSWRConfig } from 'swr';

import { usePutJSON, useDeleteAPI, useSWR } from 'hooks/api';

import { getAPI, queryParams } from 'utils';

// const PAGE_SIZE = 10;
const useGetListUsers = (query) =>
  useSWR(`/admin/users?${queryParams(query)}`, getAPI, {
    revalidateOnMount: true,
  });

const useGetStatistics = (query) =>
  useSWR(`/admin/app-reports?${queryParams(query)}`, getAPI);

const useUpdateUserRole = (query) => {
  const putJson = usePutJSON();
  const { mutate } = useSWRConfig();
  return async (id, role) => {
    try {
      if (role === 'disabled') {
        await putJson(`/admin/users/${id}`, { blocked: true });
      } else {
        await putJson(`/admin/users/${id}`, { blocked: false });
        await putJson(`/admin/users/${id}`, { role: role });
      }
      mutate(`/admin/users?${queryParams(query)}`);
    } catch (error) {
      console.error(error);
    }
  };
};

const useDeleteUserById = (query) => {
  const deleteUser = useDeleteAPI();
  const { mutate } = useSWRConfig();
  return async (userId, users) => {
    try {
      const results = users.results;
      const newUsers = {
        ...users,
        results: results.filter((b) => b.id !== userId),
      };
      // Update local data immediately
      mutate(`/admin/users?${queryParams(query)}`, newUsers, {
        optimisticData: newUsers,
        rollbackOnError: true,
        revalidate: false,
      });
      await deleteUser(`/admin/users/${userId}`);

      // Fetch again to load new items from server for paging.
      mutate(`/admin/users?${queryParams(query)}`);
    } catch (error) {
      console.error(error);
    }
  };
};

export {
  useGetListUsers,
  useUpdateUserRole,
  useDeleteUserById,
  useGetStatistics,
};
