import { message } from 'antd';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { useSWR, usePostJSON, useDeleteAPI } from 'hooks/api';

import { getAPI, queryParams } from 'utils';

export const useGetQuizStatistics = (query, id) =>
  useSWR(`/admin/statistic/quizzes/${id}?${queryParams(query)}`, getAPI);

export const useGetListGuest = (query) =>
  useSWR(`/admin/guests?${queryParams(query)}`, getAPI, {
    revalidate: true,
  });
export const useCheckGuest = () => {
  const { id } = useParams();
  return async (email) => {
    const res = await getAPI(
      `/admin/statistic/quizzes/${id}/guests?email=${btoa(email)}`,
    );

    return res;
  };
};

export const useAddGuests = (query) => {
  const postJson = usePostJSON();
  const { mutate } = useSWRConfig();
  const { formatMessage } = useIntl();
  return async (body, data) => {
    try {
      const res = await postJson(`/admin/guests`, body);
      const newData = {
        ...data,
        results: res,
      };
      message.destroy();
      message.success(
        formatMessage({ defaultMessage: '액세스 권한이 업데이트 됨' }),
      );
      mutate(`/admin/guests?${queryParams(query)}`, newData, {
        optimisticData: newData,
        rollbackOnError: true,
        revalidate: true,
      });

      return newData;
    } catch (error) {
      console.error(error);
    }
  };
};

export const useDeleteGuest = (query) => {
  const deleteGuest = useDeleteAPI();
  const { mutate } = useSWRConfig();
  return async (guestId, listGuest) => {
    const results = listGuest.results;
    const newRequests = {
      ...listGuest,
      results: results?.filter((r) => r.id !== guestId),
    };
    try {
      await deleteGuest(`/admin/guests/${guestId}`);
      mutate(`/admin/guests?${queryParams(query)}`, newRequests, {
        optimisticData: newRequests,
        rollbackOnError: true,
        revalidate: true,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const useDeleteMutipleGuest = (query) => {
  const postJson = usePostJSON();
  const { formatMessage } = useIntl();
  const { mutate } = useSWRConfig();

  return async (guestIds) => {
    try {
      await postJson(`/admin/guests:batchDelete`, { ids: guestIds });

      message.destroy();
      message.success(
        formatMessage({
          defaultMessage: '저장되었습니다.',
        }),
      );
      mutate(
        `/admin/guests?${queryParams(query)}`,
        {},
        {
          optimisticData: {},
          rollbackOnError: true,
          revalidate: true,
        },
      );
    } catch (error) {
      console.error(error);
    }
  };
};
