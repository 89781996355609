import { message } from 'antd';
import { useIntl } from 'react-intl';
import { useSWRConfig } from 'swr';

import { useDeleteAPI, usePostJSON, useSWR } from 'hooks/api';
import usePutJSON from 'hooks/api/usePutJSON';

import { getAPI } from 'utils';

const useGetAllBannerSetting = () =>
  useSWR(`/admin/home?type=banner&populate=*&sort[0]=id:desc`, getAPI);

const useAddBannerSetting = () => {
  const { mutate } = useSWRConfig();
  const postJSON = usePostJSON();
  const { formatMessage } = useIntl();

  return async (data, banners) => {
    try {
      const res = await postJSON(`/admin/home?type=banner`, data);
      const newData = [res, ...banners];
      mutate('/admin/home?type=banner&populate=*&sort[0]=id:desc', newData, {
        optimisticData: newData,
        rollbackOnError: true,
        revalidate: false,
      });
      message.destroy();
      message.success(
        formatMessage({
          defaultMessage: '저장되었습니다.',
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };
};

const useUpdateBannerSetting = () => {
  const putJSON = usePutJSON();
  const { mutate } = useSWRConfig();
  const { formatMessage } = useIntl();

  return async (id, body, banners) => {
    try {
      const res = await putJSON(`/admin/home/${id}?type=banner`, body);
      const bannerIndex = banners.findIndex((b) => b.id === id);
      message.destroy();
      message.success(
        formatMessage({
          defaultMessage: '저장되었습니다.',
        }),
      );
      banners[bannerIndex] = {
        ...banners[bannerIndex],
        ...res,
      };

      mutate(`/admin/home?type=banner&populate=*&sort[0]=id:desc`, banners, {
        optimisticData: banners,
        rollbackOnError: true,
        revalidate: false,
      });

      return res;
    } catch (error) {
      console.error(error);
    }
  };
};

const useDeleteBannerSetting = () => {
  const deleteAPI = useDeleteAPI();
  const { mutate } = useSWRConfig();

  return async (id, banners) => {
    await deleteAPI(`/admin/home/${id}?type=banner`);

    const newBanners = banners.filter((b) => b.id !== id);

    mutate(`/admin/home?type=banner&populate=*&sort[0]=id:desc`, newBanners, {
      optimisticData: newBanners,
      rollbackOnError: true,
      revalidate: false,
    });
  };
};

export {
  useGetAllBannerSetting,
  useAddBannerSetting,
  useUpdateBannerSetting,
  useDeleteBannerSetting,
};
