import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazyQuizStatistics = lazy(() => import('./QuizStatistics'));

const QuizStatistics = (props) => (
  <Loadable>
    <LazyQuizStatistics {...props} />
  </Loadable>
);

export default QuizStatistics;
