import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazyHomeTopRanking = lazy(() => import('./HomeTopRanking'));

const HomeTopRanking = (props) => (
  <Loadable>
    <LazyHomeTopRanking {...props} />
  </Loadable>
);

export default HomeTopRanking;
