import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazyCreateQuiz = lazy(() => import('./CreateQuiz'));

const CreateQuiz = (props) => (
  <Loadable>
    <LazyCreateQuiz {...props} />
  </Loadable>
);

export default CreateQuiz;
