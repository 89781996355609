import {
  BASE_URL,
  STORAGE_TOKEN,
  STORAGE_GUEST,
  EXPIRE_TIME,
} from './constants';
import { getLocalStorage, setLocalStorage } from './storage';
import { isExpired, setExpiredTime } from './utils';

async function refreshToken(token) {
  try {
    const res = await fetch(`${BASE_URL}/auth/token`, {
      method: 'POST',
      headers: newHeaders({
        ...(token && { Authorization: `Bearer ${token}` }),
      }),
    });
    if (res.ok) {
      const jsonData = await res?.json();
      if (jsonData?.jwt) {
        setLocalStorage(STORAGE_TOKEN, jsonData.jwt);
        setExpiredTime(jsonData);
      }
    }
  } catch {
    window.localStorage.removeItem(STORAGE_TOKEN);
    window.localStorage.removeItem(EXPIRE_TIME);
    window.localStorage.removeItem(STORAGE_GUEST);
  }
}

export class HttpError extends Error {
  constructor(response) {
    const { status, statusText } = response;

    super(statusText || String(status));

    this.name = 'HttpError';
    this.response = response;
  }
}

function newHeaders(headerOptions) {
  return new Headers(headerOptions ?? {});
}

async function getHeaders(headerOptions) {
  const token = getLocalStorage(STORAGE_TOKEN, '');
  const expireTime = getLocalStorage(EXPIRE_TIME, '');
  if (isExpired(expireTime)) {
    await refreshToken(token);
  }
  const guestEmail = localStorage.getItem(STORAGE_GUEST);
  const headers = await newHeaders({
    ...(token && { Authorization: `Bearer ${token}` }),
    ...(guestEmail && { 'vonvon-guest': btoa(guestEmail) }),
    ...headerOptions,
  });
  return headers;
}

async function getJSONHeaders(headerOptions) {
  const headers = await getHeaders(headerOptions);
  headers.set('content-type', 'application/json');
  return headers;
}

async function request(path, options = {}) {
  const {
    headers,
    method = 'GET',
    host = BASE_URL,
    responseType = 'json',
    ...restOptions
  } = options;

  const apiURL = `${host}${path}`;
  const newHeader = await headers;
  const requestOptions = {
    method,
    headers: newHeader,
    ...restOptions,
  };

  const response = await fetch(apiURL, requestOptions);

  if (response.ok) {
    return await response[responseType]();
  }

  throw new HttpError(response);
}

export function getAPI(path, { headers = {}, ...options } = {}) {
  return request(path, {
    method: 'GET',
    headers: getHeaders(headers),
    ...options,
  });
}

export function postAPI(path, { headers = {}, body, ...options } = {}) {
  return request(path, {
    method: 'POST',
    headers: getHeaders(headers),
    body,
    ...options,
  });
}

export function postJSON(path, { headers = {}, body = {}, ...options } = {}) {
  return request(path, {
    method: 'POST',
    headers: getJSONHeaders(headers),
    body: JSON.stringify(body),
    ...options,
  });
}

export function putAPI(path, { headers = {}, body, ...options } = {}) {
  return request(path, {
    method: 'PUT',
    headers: getHeaders(headers),
    body,
    ...options,
  });
}

export function putJSON(path, { headers = {}, body = {}, ...options } = {}) {
  return request(path, {
    method: 'PUT',
    headers: getJSONHeaders(headers),
    body: JSON.stringify(body),
    ...options,
  });
}

export function patchAPI(path, { headers = {}, body, ...options } = {}) {
  return request(path, {
    method: 'PATCH',
    headers: getHeaders(headers),
    body,
    ...options,
  });
}

export function patchJSON(path, { headers = {}, body = {}, ...options } = {}) {
  return request(path, {
    method: 'PATCH',
    headers: getJSONHeaders(headers),
    body: JSON.stringify(body),
    ...options,
  });
}

export function deleteAPI(path, { headers = {}, ...options } = {}) {
  return request(path, {
    method: 'DELETE',
    headers: getHeaders(headers),
    ...options,
  });
}
