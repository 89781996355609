import { message } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import qs from 'qs';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import useGetAPI from 'hooks/api/useGetAPI';

import {
  isQuizRevalidateAtom,
  isChangeQuizRevalidateAtom,
  apiCalledAtom,
} from 'atoms';
import {
  deleteAPI,
  getAPI,
  isQuestionBasicType,
  postJSON,
  queryParams,
} from 'utils';

import { usePostJSON, useSWR, usePutJSON } from '../api';

const defaultQuestion = {
  questionNumber: null,
  type: 'A',
  algorithmGroup: null,
  content: null,
  active: null,
  answers: [],
  style: {
    backgroundSelected: 'style',
    background: {
      image: null,
      style: {
        color: '#ffffff',
      },
    },
    progressStyle: {
      color: '#dddddd',
    },
    progressChecked: true,
    progressButtonStyle: {
      color: '#cccccc',
    },
    progressIndication: 'number',
    questionPhoto: null,
    questionPosition: 'top',
    questionStyle: {
      color: '#000000',
      font: 1,
    },
    answerStyle: {
      color: '#000000',
      font: 1,
    },
    backgroundMusic: null,
    backgroundMusicChecked: false,
    boxBackgroundImageChecked: false,
    boxBackgroundStyleChecked: false,
    boxBackgroundImage: null,
    boxBackgroundStyle: {
      color: '#EEEEEE',
    },
    boxTextStyleChecked: false,
    boxTextStyle: {
      color: '#000000',
      font: 1,
    },
    arrowStyleChecked: true,
    arrowStyle: {
      color: '#BBBBBB',
    },
    hintConfirmButtonStyle: {
      color: '#000000',
      font: 1,
    },
    hintConfirmButtonBg: {
      color: '#cccccc',
    },
    hintContentStyle: {
      color: '#000000',
      font: 1,
    },
    hintContent: '',
    wrongAnswerStyle: {
      color: '#000000',
      font: 1,
    },
    correctAnswerButtonStyle: {
      color: '#000000',
      font: 1,
    },
    correctAnswerContentStyle: {
      color: '#000000',
      font: 1,
    },
    correctAnswerContent: '',
    backgroundText: '',
    backgroundTextChecked: false,
    noticeText: '',
    noticeTextChecked: false,
    skipText: '',
    skipTextStyle: {
      color: '#000000',
      font: 1,
    },
    skipButtonStyle: {
      color: '#DDDDDD',
    },
  },
};

let queryParam = null;
let response = null;

const generateEndpoint = (action) =>
  action === 'create' ? 'quiz-drafts' : 'quizzes';

const getDefaultStyle = (activeQuestion, defaultQuestion) => {
  if (activeQuestion) {
    if (isQuestionBasicType(activeQuestion.type)) {
      return activeQuestion.style;
    } else {
      const activeStyle = activeQuestion.style;
      return {
        ...defaultQuestion.style,
        backgroundSelected: activeStyle.backgroundSelected,
        background: activeStyle.background,
        progressStyle: activeStyle.progressStyle,
        progressChecked: activeStyle.progressChecked,
        progressButtonStyle: activeStyle.progressButtonStyle,
        progressIndication: activeStyle.progressIndication,
        backgroundMusic: activeStyle.backgroundMusic,
        backgroundMusicChecked: activeStyle.backgroundMusicChecked,
      };
    }
  } else {
    return defaultQuestion.style;
  }
};

const useGetQuizzes = (query = {}) => {
  const { action } = useParams();

  queryParam = query;
  response = useSWR(
    `/admin/${generateEndpoint(action)}?${queryParams(query)}`,
    getAPI,
    {
      revalidateOnMount: true,
    },
  );
  return response;
};

const useCreateQuiz = () => {
  const { mutate } = useSWRConfig();
  return async () => {
    const res = await postJSON(`/admin/quiz-drafts`);
    mutate(`/admin/quizzes/${res.id}`, res, {
      optimisticData: res,
      rollbackOnError: true,
      revalidateOnMount: true,
    });
    return res;
  };
};

const useDeleteQuiz = (query) => {
  const { mutate } = useSWRConfig();
  const { action } = useParams();

  return async (quizID, quizzes) => {
    try {
      const results = quizzes.results;
      const newQuizzes = {
        ...quizzes,
        results: results.filter((b) => b.id !== quizID),
      };
      // Update local data immediately
      mutate(
        `/admin/${generateEndpoint(action)}?${queryParams(query)}`,
        newQuizzes,
        {
          optimisticData: newQuizzes,
          rollbackOnError: true,
          revalidate: false,
        },
      );
      await deleteAPI(`/admin/${generateEndpoint(action)}/${quizID}`);

      // Fetch again to load new items from server for paging.
      mutate(`/admin/${generateEndpoint(action)}?${queryParams(query)}`);
    } catch (error) {
      console.error(error);
    }
  };
};

const useGetQuiz = () => {
  const { id, action } = useParams();
  return useSWR(`/admin/${generateEndpoint(action)}/${id}`, getAPI);
};

const useGetHistories = (query) => {
  const { id, action } = useParams();
  const getAPI = useGetAPI();
  const { mutate } = useSWRConfig();

  return async () => {
    const res = await getAPI(
      `/admin/${generateEndpoint(action)}/${id}/histories?${queryParams(
        query,
      )}`,
    );

    mutate(
      `/admin/${generateEndpoint(action)}/${id}/histories?${queryParams(
        query,
      )}`,
      res,
      {
        optimisticData: res,
        rollbackOnError: true,
        revalidate: false,
      },
    );
  };
};

const usePostHistory = () => {
  const { id } = useParams();
  const postJSON = usePostJSON();

  return async (rollbackID) => {
    await postJSON(`/admin/quizzes/${id}/rollback/${rollbackID}`);
  };
};

const useGetQuizByUid = () => {
  const getAPI = useGetAPI();
  return (uid) => getAPI(`/admin/quizzes/${uid}`);
};

const useGetQuizByCate = () => {
  const getAPI = useGetAPI();

  return (cateID, query) =>
    getAPI(
      `/admin/categories/${!cateID ? 0 : cateID}/quizzes?${qs.stringify(
        query,
      )}`,
    );
};

const useRefreshTab = () => {
  const getAPI = useGetAPI();
  const apiCalled = useAtomValue(apiCalledAtom);
  const { id, action } = useParams();
  const { mutate } = useSWRConfig();

  return async () => {
    apiCalled.forEach(async (tab) => {
      const res = await getAPI(
        `/admin/${generateEndpoint(action)}/${id}/${tab}`,
      );
      mutate(`/admin/${generateEndpoint(action)}/${id}/${tab}`, res, {
        optimisticData: res,
        rollbackOnError: true,
        revalidate: false,
      });
    });
  };
};

const useSetTab = (tab) => {
  const [apiCalled, setApiCalledAtom] = useAtom(apiCalledAtom);

  return () => {
    if (!apiCalled.includes(tab) && tab !== 'history') {
      const newApiCalled = [...apiCalled, tab];
      setApiCalledAtom(newApiCalled);
    }
  };
};

const useGetTab = (tab, query) => {
  const { id, action } = useParams();
  const isRevalidate = useAtomValue(isQuizRevalidateAtom);
  const isAlgorithmsRevalidate = useAtomValue(isChangeQuizRevalidateAtom);

  return useSWR(
    `/admin/${generateEndpoint(action)}/${id}/${tab}${
      query ? `?${queryParams(query)}` : ''
    }`,
    getAPI,
    {
      revalidateIfStale: isRevalidate || isAlgorithmsRevalidate,
    },
  );
};

const useUpdateTab = (tab) => {
  const { id, action } = useParams();
  const { formatMessage } = useIntl();
  const { mutate } = useSWRConfig();
  const putJSON = usePutJSON();

  return async (data, showSuccessMsg = true) => {
    try {
      const res = await putJSON(
        `/admin/${generateEndpoint(action)}/${id}/${tab}`,
        data,
      );
      mutate(`/admin/${generateEndpoint(action)}/${id}/${tab}`, res, {
        optimisticData: res,
        rollbackOnError: true,
        revalidate: false,
      });
      if (tab === 'algorithms') {
        mutate(`/admin/${generateEndpoint(action)}/${id}/results`, null, {
          optimisticData: null,
          rollbackOnError: true,
          revalidate: false,
        });
      }

      if (res?.data) {
        const results = response.data.results;
        const quizIndex = results.findIndex((b) => b.id === parseInt(id));
        results[quizIndex] = {
          ...results[quizIndex],
          ...res,
        };
        const newQuizzes = { ...response?.data, results };
        // Update local data immediately
        mutate(
          `/admin/${generateEndpoint(action)}?${queryParams(queryParam)}`,
          newQuizzes,
          {
            optimisticData: newQuizzes,
            rollbackOnError: true,
            revalidate: false,
          },
        );
      }

      showSuccessMsg &&
        message.success(
          formatMessage({
            defaultMessage: '저장되었습니다.',
          }),
        );
      return res;
    } catch (error) {
      console.error(error);
    }
  };
};

const useUpdateBasicInfo = (query) => {
  const { mutate } = useSWRConfig();
  const putJSON = usePutJSON();
  const { action } = useParams();

  return async (quizID, body, quizzes) => {
    try {
      const quiz = await putJSON(
        `/admin/${generateEndpoint(action)}/${quizID}/basic`,
        body,
      );
      const results = quizzes.results;
      const quizIndex = results.findIndex((b) => b.id === parseInt(quizID));
      results[quizIndex] = {
        ...results[quizIndex],
        ...quiz,
      };
      const newQuizzes = { ...quizzes, results };
      mutate(`/admin/quizzes?${queryParams(query)}`, newQuizzes, {
        optimisticData: newQuizzes,
        rollbackOnError: true,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

const useUpdateAllResults = () => {
  const { id, action } = useParams();
  const { data: results } = useGetTab('results');
  const { mutate } = useSWRConfig();

  return (allResults) => {
    const newRes = {
      ...results,
      results: allResults,
    };
    mutate(`/admin/${generateEndpoint(action)}/${id}/results`, newRes, {
      optimisticData: newRes,
      rollbackOnError: true,
      revalidate: false,
    });
  };
};

const useGetResults = () => {
  const { id, action } = useParams();
  const { mutate } = useSWRConfig();

  const getAPI = useGetAPI();

  return async () => {
    const res = await getAPI(
      `/admin/${generateEndpoint(action)}/${id}/results`,
    );
    mutate(`/admin/${generateEndpoint(action)}/${id}/results`, res, {
      optimisticData: res,
      rollbackOnError: true,
      revalidate: false,
    });
    return res;
  };
};

const useCreateResult = (tab) => {
  const { id, action } = useParams();
  const { mutate } = useSWRConfig();
  const { data: results } = useGetTab('results');
  const postJSON = usePostJSON();

  return async (data, allResults) => {
    try {
      const res = await postJSON(
        `/admin/${generateEndpoint(action)}/${id}/${tab}`,
        data,
      );
      const newRes = {
        ...results,
        results: [...allResults, { ...res, isAddNew: true }],
      };
      mutate(`/admin/${generateEndpoint(action)}/${id}/${tab}`, newRes, {
        optimisticData: newRes,
        rollbackOnError: true,
        revalidate: false,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

const useDeleteResult = () => {
  const { id, action } = useParams();
  const { mutate } = useSWRConfig();

  return async (resultID, results) => {
    try {
      await deleteAPI(
        `/admin/${generateEndpoint(action)}/${id}/results/${resultID}`,
      );
      const newResults = {
        ...results,
        results: results.results.filter((b) => b.id !== resultID),
      };
      mutate(`/admin/${generateEndpoint(action)}/${id}/results`, newResults, {
        optimisticData: newResults,
        rollbackOnError: true,
        revalidate: false,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

const useGetQuestions = () => {
  const { id, action } = useParams();
  const { mutate } = useSWRConfig();

  const getAPI = useGetAPI();

  return async () => {
    const res = await getAPI(
      `/admin/${generateEndpoint(action)}/${id}/questions`,
    );
    mutate(`/admin/${generateEndpoint(action)}/${id}/questions`, res, {
      optimisticData: res,
      rollbackOnError: true,
      revalidate: false,
    });
    return res;
  };
};

const useCreateQuestion = () => {
  const { id, action } = useParams();
  const postJSON = usePostJSON();
  const { mutate } = useSWRConfig();

  return async (defaultAlgorithmGroup, activeQuestion, questions) => {
    const res = await postJSON(
      `/admin/${generateEndpoint(action)}/${id}/questions`,
      {
        ...defaultQuestion,
        style: getDefaultStyle(activeQuestion, defaultQuestion),
        algorithmGroup: defaultAlgorithmGroup,
      },
    );

    const newRes = {
      ...questions,
      questions: [...questions, res],
    };
    mutate(`/admin/${generateEndpoint(action)}/${id}/questions`, newRes, {
      optimisticData: newRes,
      rollbackOnError: true,
      revalidate: false,
    });
    return res;
  };
};

const useDeleteQuestion = () => {
  const { id, action } = useParams();
  const { mutate } = useSWRConfig();

  return async (questionId, questions) => {
    await deleteAPI(
      `/admin/${generateEndpoint(action)}/${id}/questions/${questionId}`,
    );

    let newQuestion = questions.filter((b) => b.id !== questionId);
    newQuestion = newQuestion.map((item) => {
      item?.answers?.map((anw) => {
        if (anw.step === questionId.toString()) {
          anw.step = 'next';
        }
        return anw;
      });
      return item;
    });
    const newRes = {
      ...questions,
      questions: newQuestion,
    };
    mutate(`/admin/${generateEndpoint(action)}/${id}/questions`, newRes, {
      optimisticData: newRes,
      rollbackOnError: true,
      revalidate: false,
    });
  };
};

const useCheckBestContent = () => {
  const getAPI = useGetAPI();

  return async (id) =>
    await getAPI(`/admin/quizzes/${id}/status?props=isBestContent`);
};

const useCreateBanner = () => {
  const { id, action } = useParams();
  const postJSON = usePostJSON();
  const { mutate } = useSWRConfig();
  return async (banners) => {
    const banner = await postJSON(
      `/admin/${generateEndpoint(action)}/${id}/banners`,
    );
    const newBanners = { banners: [...banners, banner] };
    mutate(`/admin/${generateEndpoint(action)}/${id}/banners`, newBanners, {
      optimisticData: newBanners,
      rollbackOnError: true,
      revalidate: false,
    });
  };
};

const useUpdateBanner = () => {
  const { id, action } = useParams();
  const putJSON = usePutJSON();
  const { formatMessage } = useIntl();
  const { mutate } = useSWRConfig();

  return async (bannerId, body, banners) => {
    const banner = await putJSON(
      `/admin/${generateEndpoint(action)}/${id}/banners/${bannerId}`,
      body,
    );
    const bannerIndex = banners.findIndex((b) => b.id === parseInt(bannerId));
    banners[bannerIndex] = banner;
    const newBanners = { banners: [...banners] };

    mutate(`/admin/${generateEndpoint(action)}/${id}/banners`, newBanners, {
      optimisticData: newBanners,
      rollbackOnError: true,
      revalidate: false,
    });
    if (banner) {
      message.success(
        formatMessage({
          defaultMessage: '저장되었습니다.',
        }),
      );
    }
  };
};

const useDeleteBanner = () => {
  const { id, action } = useParams();
  const { mutate } = useSWRConfig();
  return async (bannerId, banners) => {
    await deleteAPI(
      `/admin/${generateEndpoint(action)}/${id}/banners/${bannerId}`,
    );
    const newBanners = { banners: banners.filter((b) => b.id !== bannerId) };
    mutate(`/admin/${generateEndpoint(action)}/${id}/banners`, newBanners, {
      optimisticData: newBanners,
      rollbackOnError: true,
      revalidate: false,
    });
  };
};

const useUpdatePromotion = (query) => {
  const { mutate } = useSWRConfig();
  const putJSON = usePutJSON();
  const { action } = useParams();

  return async (quizID, body, quizzes) => {
    try {
      const quiz = await putJSON(
        `/admin/${generateEndpoint(action)}/${quizID}/promotions`,
        body,
      );
      const results = quizzes.results;
      const quizIndex = results.findIndex((b) => b.id === parseInt(quizID));
      results[quizIndex] = {
        ...results[quizIndex],
        ...quiz,
      };
      const newQuizzes = { ...quizzes, results };

      mutate(
        `/admin/${generateEndpoint(action)}?${queryParams(query)}`,
        newQuizzes,
        {
          optimisticData: newQuizzes,
          rollbackOnError: true,
          revalidate: false,
        },
      );
    } catch (error) {
      console.error(error);
    }
  };
};

const useGetCurrentAdmins = () => {
  const { id, action } = useParams();
  if (action === 'create') {
    return {};
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useSWR(`/admin/quizzes/${id}/admins`, getAPI, {
    revalidateOnMount: true,
    refreshInterval: 1 * 60 * 1000,
  });
};

const useCurrentAdminIn = () => {
  const { id } = useParams();
  const postJSON = usePostJSON();

  return () => postJSON(`/admin/quizzes/${id}/admins/login`);
};

const useCurrentAdminOut = () => {
  const { id } = useParams();
  const postJSON = usePostJSON();

  return () => postJSON(`/admin/quizzes/${id}/admins/logout`);
};

export {
  useGetQuizzes,
  useCreateQuiz,
  useDeleteQuiz,
  useGetQuiz,
  useGetHistories,
  usePostHistory,
  useSetTab,
  useGetTab,
  useUpdateTab,
  useRefreshTab,
  useUpdateBasicInfo,
  useUpdateAllResults,
  useGetResults,
  useCreateResult,
  useDeleteResult,
  useGetQuestions,
  useCreateQuestion,
  useDeleteQuestion,
  useCreateBanner,
  useUpdateBanner,
  useDeleteBanner,
  useGetQuizByUid,
  useUpdatePromotion,
  useGetCurrentAdmins,
  useCurrentAdminIn,
  useCurrentAdminOut,
  useGetQuizByCate,
  useCheckBestContent,
};
