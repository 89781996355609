import ApprovalRequest from 'containers/ApprovalRequest';
import ContentEdited from 'containers/ContentEdited';
import HomeBanner from 'containers/HomeBanner';
import HomeCategory from 'containers/HomeCategory';
import HomeTopRanking from 'containers/HomeTopRanking';
import Login from 'containers/Login';
import MemberManagement from 'containers/MemberManagement';
import NotFoundPage from 'containers/NotFoundPage';
import OriginalCategory from 'containers/OriginalCategory';
import Quiz from 'containers/Quiz';
import CreateQuiz from 'containers/Quiz/CreateQuiz';
import QuizStatistics from 'containers/QuizStatistics';
import SecretKeyword from 'containers/SecretKeyword';
import Signup from 'containers/Signup';
import UploadedContent from 'containers/UploadedContent';
import UserManagement from 'containers/UserManagement';
import { Navigate } from 'react-router-dom';

import { BaseLayout, Layout, SecondaryLayout } from 'components/Layout';
//import DO NOT DELETE THIS LINE

const routes = (isAdmin, isManager, isMember, isLoggedIn) => [
  {
    path: '/',
    element: !isLoggedIn ? (
      <></>
    ) : isAdmin || isManager || isMember ? (
      <Layout />
    ) : (
      <NotFoundPage> </NotFoundPage>
    ),
    children: [
      { index: true, element: <UploadedContent /> },
      { path: '/content-edited', element: <ContentEdited /> },
      { path: '/user-management', element: <UserManagement /> },
      { path: '/member-management', element: <MemberManagement /> },
      //component DO NOT DELETE THIS LINE
    ],
  },
  {
    path: '/',
    element: !isLoggedIn ? (
      <></>
    ) : isAdmin || isManager ? (
      <Layout />
    ) : (
      <NotFoundPage> </NotFoundPage>
    ),
    children: [
      { path: '/approval-request', element: <ApprovalRequest /> },
      { path: '/secret-keyword', element: <SecretKeyword /> },

      { path: '/home-banner', element: <HomeBanner /> },
      { path: '/home-top-ranking', element: <HomeTopRanking /> },
      { path: '/home-category', element: <HomeCategory /> },
      { path: '/original-category', element: <OriginalCategory /> },
      //component DO NOT DELETE THIS LINE
    ],
  },
  {
    path: '/quiz/create',
    element: !isLoggedIn ? (
      <></>
    ) : isAdmin || isManager || isMember ? (
      <SecondaryLayout />
    ) : (
      <> </>
    ),
    children: [
      {
        index: true,
        element: <CreateQuiz />,
      },
    ],
  },
  {
    path: '/quiz/:action/:id',
    element: !isLoggedIn ? (
      <></>
    ) : isAdmin || isManager || isMember ? (
      <SecondaryLayout />
    ) : (
      <> </>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="basic-information" replace />,
        replace: true,
      },
      { path: ':source', element: <Quiz /> },
    ],
  },
  {
    path: '/quiz-statistics/:id', //for login user
    element: <SecondaryLayout />,
    children: [
      {
        index: true,
        element: <QuizStatistics />,
      },
    ],
  },
  {
    path: 'quizzes/:id', //for guest
    element: <SecondaryLayout isGuest={true} />,
    children: [
      {
        index: true,
        element: <QuizStatistics isGuest={true} />,
      },
    ],
  },
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      { path: '/login', element: <Login /> },
      { path: '/signup', element: <Signup /> },
    ],
  },
  { path: '*', element: <NotFoundPage /> },
];

export default routes;
