import { Layout } from 'antd';
import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';

import Header from 'components/Header';

import { getLocalStorage, STORAGE_TOKEN } from 'utils';

const ContentStyled = styled(Layout.Content)`
  margin-top: 60px;

  @media (min-width: 992px) {
    margin-top: 80px;
  }
`;

const SecondaryLayout = ({ isGuest }) => {
  if (getLocalStorage(STORAGE_TOKEN) || isGuest) {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Header hasCreateTestButton={false} />
        <ContentStyled>
          <Outlet />
        </ContentStyled>
      </Layout>
    );
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default SecondaryLayout;
