// import { useIntl } from 'react-intl';
import { Menu } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import adminBannerIcon from 'assets/images/admin-banner-icon.png';
import adminIcon from 'assets/images/admin-icon.png';
import adminSettingIcon from 'assets/images/admin-setting-icon.png';
import ArrowDownIcon from 'assets/images/arrow-down.svg?rc';
import contentIcon from 'assets/images/content-icon.png';
import { useGetMe } from 'hooks/services';

const MenuTitleStyled = styled.h2`
  flex-grow: 0;
  margin: 40px 0 16px 20px;
  font-size: 24px;
  font-weight: 900;
  color: #383c40;
`;

const MenuStyled = styled(Menu)`
  padding-bottom: 60px; // trigger
  user-select: none;
  .ant-menu-sub {
    margin-left: 20px;
    width: calc(100% - 40px);
    border-radius: 10px;
    padding: 0 10px;

    .ant-menu-item {
      padding-left: 20px !important;
      border-radius: 10px;

      &:first-child {
        margin-top: 20px;
      }
      &:last-child {
        margin-bottom: 20px;
      }
    }

    .ant-menu-item-selected {
      background-color: #ff5258;
      a {
        color: #fff;
      }
    }
  }
`;

function getPath(menu, key) {
  let path,
    item = menu.key;

  if (!menu || typeof menu !== 'object') return;

  if (menu.key === key) return [item];

  (menu.children || []).some((child) => (path = getPath(child, key)));
  return path && [item, ...path];
}

function getOpenedKeys(menu, key) {
  let r;
  menu.forEach((item) => {
    const path = getPath(item, key);
    if (path) {
      r = path;
    }
  });
  return r;
}

const Sidebar = () => {
  const { formatMessage } = useIntl();
  const { data } = useGetMe();
  const isMember = data?.role?.type === 'member';
  const isManager = data?.role?.type === 'admin';
  const items = useMemo(
    () => [
      {
        label: formatMessage({ defaultMessage: '전체 콘텐츠' }),
        key: 'content',
        icon: <img src={contentIcon} alt="content icon" />,
        children: [
          {
            label: (
              <Link to="/">
                {formatMessage({ defaultMessage: '업로드된 콘텐츠' })}
              </Link>
            ),
            key: '/',
          },
          {
            label: (
              <Link to="/content-edited">
                {formatMessage({ defaultMessage: '편집중인 콘텐츠' })}
              </Link>
            ),
            key: '/content-edited',
          },
        ],
      },
      {
        label: formatMessage({ defaultMessage: '어드민' }),
        key: 'admin',
        icon: <img src={adminIcon} alt="content icon" />,
        children: [
          {
            label: (
              <Link to="/user-management">
                {formatMessage({ defaultMessage: '사용자 관리' })}
              </Link>
            ),
            key: '/user-management',
            disabled: isManager,
          },
          {
            label: (
              <Link to="/approval-request">
                {formatMessage({ defaultMessage: '승인요청' })}
              </Link>
            ),
            key: '/approval-request',
            disabled: isMember || isManager,
          },
          {
            label: (
              <Link to="/secret-keyword">
                {formatMessage({ defaultMessage: '시크릿 키워드' })}
              </Link>
            ),
            key: '/secret-keyword',
            disabled: isMember || isManager,
          },
          {
            label: (
              <Link to="/member-management">
                {formatMessage({ defaultMessage: '회원관리' })}
              </Link>
            ),
            key: '/member-management',
          },
        ],
      },
      {
        label: (
          <Link to="/home-banner">
            {formatMessage({ defaultMessage: '홈 스포트라이트 배너관리' })}
          </Link>
        ),
        key: '/home-banner',
        icon: <img src={adminBannerIcon} alt="content icon" />,
        disabled: isMember,
      },
      {
        label: formatMessage({ defaultMessage: '홈 화면 노출 설정' }),
        key: 'home-settings',
        icon: <img src={adminSettingIcon} alt="content icon" />,
        disabled: isMember,
        children: [
          {
            label: (
              <Link to="/home-top-ranking">
                {formatMessage({ defaultMessage: '홈 상단 순위' })}
              </Link>
            ),
            key: '/home-top-ranking',
          },
          {
            label: (
              <Link to="/home-category">
                {formatMessage({ defaultMessage: '홈 카테고리' })}
              </Link>
            ),
            key: '/home-category',
          },
          {
            label: (
              <Link to="/original-category">
                {formatMessage({ defaultMessage: '봉봉 오리지널 카테고리' })}
              </Link>
            ),
            key: '/original-category',
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { pathname } = useLocation();
  const [sideBarOpenedKeys, setSideBarOpenedKeys] = useState([]);

  useEffect(() => {
    setSideBarOpenedKeys(getOpenedKeys(items, pathname));
  }, [items, pathname]);

  return (
    <>
      <MenuTitleStyled>
        {formatMessage({ defaultMessage: '메뉴' })}
      </MenuTitleStyled>
      <MenuStyled
        mode="inline"
        items={items}
        onOpenChange={(items) => setSideBarOpenedKeys(items)}
        openKeys={sideBarOpenedKeys}
        selectedKeys={[pathname]}
        expandIcon={({ isOpen }) => (
          <ArrowDownIcon
            style={{
              transform: isOpen ? 'rotate(0)' : 'rotate(-180deg)',
            }}
          />
        )}
      />
    </>
  );
};

export default Sidebar;
