import { useSWR } from 'hooks/api';

import { getAPI } from 'utils';

const INTERVAL = 10; // minutes

export default function useGetSecretCode() {
  return useSWR('/admin/secret-code', getAPI, {
    revalidateOnMount: true,
    refreshInterval: INTERVAL * 60 * 1000,
  });
}
