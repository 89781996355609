import { useSWRConfig } from 'swr';

import { usePutJSON, useDeleteAPI, useSWR } from 'hooks/api';

import { getAPI, queryParams } from 'utils';

// const PAGE_SIZE = 10;
const useGetListRequests = (query) =>
  useSWR(`/approval-requests?${queryParams(query)}`, getAPI);

const useApproveRequest = (query) => {
  const putJson = usePutJSON();
  const { mutate } = useSWRConfig();
  return async (id, roleType, requests) => {
    try {
      await putJson(`/approval-requests/${id}/approve`, {
        role: roleType,
      });
      const newRequests = {
        ...requests,
        results: requests?.results?.filter((r) => r.id !== id),
      };
      mutate(`/approval-requests?${queryParams(query)}`, newRequests, {
        optimisticData: newRequests,
        rollbackOnError: true,
        revalidate: true,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

const useDeleteRequest = (query) => {
  const deleteApprove = useDeleteAPI();
  const { mutate } = useSWRConfig();
  return async (requestId, requests) => {
    const results = requests.results;
    const newRequests = {
      ...requests,
      results: results.filter((r) => r.id !== requestId),
    };
    try {
      await deleteApprove(`/approval-requests/${requestId}`);
      mutate(`/approval-requests?${queryParams(query)}`, newRequests, {
        optimisticData: newRequests,
        rollbackOnError: true,
        revalidate: true,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export { useDeleteRequest, useApproveRequest, useGetListRequests };
