import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazyUploadedContent = lazy(() => import('./UploadedContent'));

const UploadedContent = (props) => (
  <Loadable>
    <LazyUploadedContent {...props} />
  </Loadable>
);

export default UploadedContent;
