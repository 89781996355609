import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazyUserManagement = lazy(() => import('./UserManagement'));

const UserManagement = (props) => (
  <Loadable>
    <LazyUserManagement {...props} />
  </Loadable>
);

export default UserManagement;
