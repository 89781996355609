import dayjs from 'dayjs';
import qs from 'qs';

import { ALGORITHM_TYPES, EXPIRE_TIME, QUESTION_TYPES } from './constants';
import { setLocalStorage } from './storage';

export const queryParams = ({ pagination, ...rest }) =>
  qs.stringify(
    { pagination: { pageSize: 10, ...pagination }, ...rest },
    { skipNulls: true },
  );

export const generateMBTIResults = (arrays) =>
  arrays.reduce(
    (results, array) =>
      results.flatMap((result) => array.map((a) => [...result, a].join(''))),
    [''],
  );

export const merge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], merge(target[key], source[key]));
  }

  // Join `target` and modified `source`
  return Object.assign({}, target || {}, source);
};

export function gcd(val, lim = 50) {
  let lower = [0, 1];
  let upper = [1, 0];

  // eslint-disable-next-line no-constant-condition
  while (true) {
    let mediant = [lower[0] + upper[0], lower[1] + upper[1]];

    if (val * mediant[1] > mediant[0]) {
      if (lim < mediant[1]) {
        return upper;
      }
      lower = mediant;
    } else if (val * mediant[1] === mediant[0]) {
      if (lim >= mediant[1]) {
        return mediant;
      }
      if (lower[1] < upper[1]) {
        return lower;
      }
      return upper;
    } else {
      if (lim < mediant[1]) {
        return lower;
      }
      upper = mediant;
    }
  }
}

export function getAspectRatio(width, height) {
  let values;
  if (Array.isArray(width)) {
    values = gcd(width[1] ? width[0] / width[1] : 1, 50);
  } else {
    values = gcd(height ? width / height : 1, 50);
  }
  return values[0] / values[1];
}

export const validateEmail = (email) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  );

export const isQuestionTypeA = (type) => type === QUESTION_TYPES[0].value;
export const isQuestionTypeB = (type) => type === QUESTION_TYPES[1].value;
export const isQuestionTypeC = (type) => type === QUESTION_TYPES[2].value;
export const isQuestionTypeD = (type) => type === QUESTION_TYPES[3].value;
export const isQuestionTypeSA = (type) => type === QUESTION_TYPES[4].value;
export const isQuestionTypeSSA = (type) => type === QUESTION_TYPES[5].value;
export const isQuestionTypeSSB = (type) => type === QUESTION_TYPES[6].value;
export const isQuestionBasicType = (type) =>
  isQuestionTypeA(type) ||
  isQuestionTypeB(type) ||
  isQuestionTypeC(type) ||
  isQuestionTypeD(type);

export const isAlgorithmPersonality = (type) =>
  type === ALGORITHM_TYPES[0].value;
export const isAlgorithmRandom = (type) => type === ALGORITHM_TYPES[1].value;
export const isAlgorithmPoint = (type) => type === ALGORITHM_TYPES[2].value;
export const mergeDeep = (...objects) => {
  const isObject = (obj) => obj && typeof obj === 'object';

  return objects.reduce((prev, obj) => {
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
};

export const isExpired = (date) => {
  const expireDate = dayjs(date);
  const currentDay = dayjs();

  return currentDay > expireDate.subtract(1, 'hours');
};

export const setExpiredTime = (res) => {
  if (res?.jwt) {
    const payloadData = JSON.parse(atob(res?.jwt?.split('.')?.[1]));
    setLocalStorage(EXPIRE_TIME, dayjs.unix(payloadData.exp));
  }
};

export const calculatorPoint = (
  index,
  item,
  nextItem,
  formatMessage,
  length,
  isDefault = false,
) => {
  item.isOverlap = false;
  item.isAlignment = false;
  const overlapMsg = formatMessage({
    defaultMessage: '[중복]',
  });
  const alignmentMsg = formatMessage({
    defaultMessage: '[정렬필요]',
  });
  item.order = index + 1;

  if (length === 1 && !item.maxScore && isDefault) {
    item['minScore'] = 0;
    item.maxScore = 100;
    item.isOverlap = false;
    item.isAlignment = false;

    return item;
  }

  if (nextItem) {
    const minScore = nextItem.maxScore;
    const score = item.maxScore;

    if (minScore > score) {
      item['minScore'] = alignmentMsg;
      item.isAlignment = true;
      item.isOverlap = false;
    } else if (minScore === score) {
      item['minScore'] = overlapMsg;
      item.isOverlap = true;
      item.isAlignment = false;
    } else {
      item['minScore'] = minScore + 1;
      item.isOverlap = false;
      item.isAlignment = false;
    }
  } else {
    item['minScore'] = 0;
    if (!item?.maxScore) {
      item['maxScore'] = 0;
    }
    item.isOverlap = false;
    item.isAlignment = false;
  }
  if (index === 0 && !item?.maxScore && isDefault) {
    item['minScore'] = 0;
    item.maxScore = 100;
    item.isOverlap = false;
    item.isAlignment = false;
  }
  return item;
};
