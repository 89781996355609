const LOCALES = {
  en: 'en',
  ko: 'ko',
};

const LANGUAGES = [
  { name: 'English', code: LOCALES.en },
  { name: 'Korean', code: LOCALES.ko },
];

const DEFAULT_LOCALE = LOCALES.ko;

exports.LOCALES = LOCALES;
exports.LANGUAGES = LANGUAGES;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;
