import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazyOriginalCategory = lazy(() => import('./OriginalCategory'));

const OriginalCategory = (props) => (
  <Loadable>
    <LazyOriginalCategory {...props} />
  </Loadable>
);

export default OriginalCategory;
