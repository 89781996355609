import { message } from 'antd';
import { useIntl } from 'react-intl';

import { useLogout } from 'hooks/services';

// https://docs.strapi.io/developer-docs/latest/developer-resources/error-handling.html#rest-errors
export default function useApiGlobalError() {
  const { formatMessage } = useIntl();
  const logout = useLogout();

  return (error) => {
    switch (error.status) {
      case 401:
      case 403:
        if (window.location.pathname.includes('quizzes')) {
          break;
        } else {
          logout();
          break;
        }
      default:
        message.destroy();
        message.error(
          error.message
            ? formatMessage({
                id: error.message,
                defaultMessage: [],
              })
            : formatMessage({
                defaultMessage: 'Something went wrong. Try reload the page',
              }),
        );
        break;
    }
  };
}
