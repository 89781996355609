import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazyQuiz = lazy(() => import('./Quiz'));

const Quiz = (props) => (
  <Loadable>
    <LazyQuiz {...props} />
  </Loadable>
);

export default Quiz;
