import useApiGlobalError from './useApiGlobalError';
import useDeleteAPI from './useDeleteAPI';
import useGetAPI from './useGetAPI';
import usePostAPI from './usePostAPI';
import usePostJSON from './usePostJSON';
import usePutJSON from './usePutJSON';
import useSWR from './useSWR';

export {
  useApiGlobalError,
  useGetAPI,
  usePostJSON,
  usePostAPI,
  usePutJSON,
  useDeleteAPI,
  useSWR,
};
