import { useAtomsDevtools } from 'jotai/devtools';
import { useRoutes } from 'react-router-dom';

import { useGetMe } from 'hooks/services';

import { routes } from 'utils';

function App() {
  const { data } = useGetMe();
  const isAdmin = data?.role?.type === 'master';
  const isManager = data?.role?.type === 'admin';
  const isMember = data?.role?.type === 'member';
  const isLoggedIn = !!data;
  useAtomsDevtools();
  const element = useRoutes(routes(isAdmin, isManager, isMember, isLoggedIn));

  return element;
}

export default App;
