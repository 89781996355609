import { getAPI } from 'utils';

import useApiGlobalError from './useApiGlobalError';

export default function useGetAPI() {
  const errorHandler = useApiGlobalError();

  return async (path, options) => {
    try {
      return await getAPI(path, options);
    } catch (error) {
      const errorResponse = await error.response.json();
      errorHandler(errorResponse.error);
      throw errorResponse.error;
    }
  };
}
