import { lazy } from 'react';

import Loadable from 'components/Loadable';

const LazyContentEdited = lazy(() => import('./ContentEdited'));

const ContentEdited = (props) => (
  <Loadable>
    <LazyContentEdited {...props} />
  </Loadable>
);

export default ContentEdited;
