import { useSWR } from 'hooks/api';
import usePutJSON from 'hooks/api/usePutJSON';

import { getAPI, queryParams } from 'utils';

const useGetRecommendation = (tab) =>
  useSWR(`/admin/home?type=recommendation&tab=${tab}`, getAPI);

const useGetCategory = (query) =>
  useSWR(`/admin/categories?${queryParams(query)}`, getAPI);

const useUpdateRecommendation = (tab) => {
  const putJSON = usePutJSON();

  return async (body) => {
    try {
      const res = await putJSON(
        `/admin/home?type=recommendation&tab=${tab}`,
        body,
      );
      return res;
    } catch (error) {
      console.error(error);
    }
  };
};
export { useGetRecommendation, useUpdateRecommendation, useGetCategory };
