import useCheckAccountAvailable from './useCheckAccountAvailable';
import useGetCategories from './useGetCategories';
import useGetMe, { useIsAdmin } from './useGetMe';
import useGetSecretCode from './useGetSecretCode';
import useLogout from './useLogout';
import useSignup from './useSignup';
import useUpload from './useUpload';
export * from './useQuiz';
export * from './useBestContent';
export * from './useApproveRequest';
export * from './useUser';
export * from './useRecommendation';
export * from './useBannerSetting';
export * from './useLogin';
export * from './useStatistics';

export {
  useCheckAccountAvailable,
  useSignup,
  useLogout,
  useUpload,
  useGetMe,
  useIsAdmin,
  useGetCategories,
  useGetSecretCode,
};
