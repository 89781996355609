import { MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Drawer, Layout } from 'antd';
import { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';

import { getLocalStorage, STORAGE_TOKEN } from 'utils';

const siderWidth = 300;
const siderMobileWidth = 280;
const headerHeight = 80;
const headerMobileHeight = 60;

const SiderStyled = styled(Layout.Sider)`
  position: fixed;
  height: ${(props) => css`
    calc(100vh - ${props.top}px)
  `};
  overflow: auto;
  left: 0;
  top: ${(props) => props.top}px;
  bottom: 0;
  &.show-lg {
    display: none;
  }
  width: 280px !important;
  min-width: 280px !important;
  max-width: 280px !important;

  @media (min-width: 992px) {
    width: 300px !important;
    min-width: 300px !important;
    max-width: 300px !important;
    &.show-lg {
      display: block;
    }
  }
`;

const DrawerStyled = styled(Drawer)`
  display: block;

  @media (min-width: 992px) {
    display: none;
  }
`;

const ContentStyled = styled(Layout.Content)`
  margin-left: 0;
  padding: 20px;
  margin-top: ${headerMobileHeight}px;

  @media (min-width: 992px) {
    padding: 60px;
    margin-left: ${siderWidth}px;
    margin-top: ${headerHeight}px;
  }
`;

const ButtonStyled = styled(Button)`
  position: fixed;
  bottom: 30px;
  left: 20px;
  height: 50px;
  width: 50px;
  box-shadow: 1px 1px 3px #a9a1a1;
  display: block;
  z-index: 99;

  @media (min-width: 992px) {
    display: none;
  }
`;

const PrimaryLayout = () => {
  const [visible, setVisible] = useState(false);

  if (getLocalStorage(STORAGE_TOKEN)) {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Header />
        <Layout>
          <SiderStyled top={headerHeight} className="show-lg">
            <Sidebar />
          </SiderStyled>
          <ButtonStyled
            icon={<MenuUnfoldOutlined />}
            type="primary"
            shape="circle"
            onClick={() => setVisible(true)}
          ></ButtonStyled>
          <DrawerStyled
            placement="left"
            onClose={() => setVisible(false)}
            visible={visible}
            closable={false}
            width={siderMobileWidth}
          >
            <SiderStyled top={0}>
              <Sidebar />
            </SiderStyled>
          </DrawerStyled>
          <ContentStyled>
            <Outlet />
          </ContentStyled>
        </Layout>
      </Layout>
    );
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default PrimaryLayout;
