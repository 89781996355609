import { useSWRConfig } from 'swr';

import { useSWR, usePutJSON } from 'hooks/api';

import { getAPI } from 'utils';

const useGetBestContent = () =>
  useSWR(
    '/admin/home?type=best-content&pagination[page]=1&pagination[pageSize]=7',
    getAPI,
  );

const useUpdateBestContent = () => {
  const putJson = usePutJSON();
  const { mutate } = useSWRConfig();
  return async (body, data) => {
    try {
      const res = await putJson(`/admin/home?type=best-content`, body);
      const newData = {
        ...data,
        results: res,
      };
      mutate(
        `/admin/home?type=best-content&pagination[page]=1&pagination[pageSize]=7`,
        newData,
        {
          optimisticData: newData,
          rollbackOnError: true,
          revalidate: false,
        },
      );
      return newData;
    } catch (error) {
      console.error(error);
    }
  };
};

export { useGetBestContent, useUpdateBestContent };
